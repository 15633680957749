import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import MicIcon from '@mui/icons-material/Mic';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import YouTubeIcon from '@mui/icons-material/YouTube';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import Link from "@mui/material/Link";
import {Link as RouterLink, useLocation} from "react-router-dom";
import {NavLink} from "react-router-dom";
import {useEffect, useState} from "react";
import { useUser } from '../../context/UserContext';
import { useTranslation } from 'react-i18next';

const LIGHT_PURPLE = '#B975FF';

export function MainListItems() {
    const [selectedPage, setSelectedPage] = useState('/dashboard')
    const location = useLocation()
    const { hasFeatureAccess } = useUser();
    const { t } = useTranslation();
    useEffect(() => {
        setSelectedPage(location.pathname)
    }, [location]);

    const baseNavItems = [
        {
            path: "/dashboard",
            icon: <DashboardIcon/>,
            label: t("dashboard_navigation.home")
        },
        {
            path: "/dashboard/channel-edit",
            icon: <AdminPanelSettingsIcon/>,
            label: t("dashboard_navigation.channel_edit")
        },
        ...(hasFeatureAccess('owner_bot') ? [{
            path: "/dashboard/bot-management",
            icon: <SmartToyIcon/>,
            label: t("dashboard_navigation.bot_management")
        }] : []),
        {
            path: "/dashboard/tts-settings",
            icon: <MicIcon/>,
            label: t("dashboard_navigation.tts_settings")
        },
        {
            path: "/dashboard/rewards",
            icon: <EmojiEventsIcon/>,
            label: t("dashboard_navigation.rewards")
        },
        {
            path: "/dashboard/voices",
            icon: <RecordVoiceOverIcon/>,
            label: t("dashboard_navigation.voices")
        }
    ];

    // Add YouTube Proxy to navigation only if user has access
    if (hasFeatureAccess('youtube_proxy')) {
        baseNavItems.push({
            path: "/dashboard/youtube-proxy",
            icon: <YouTubeIcon/>,
            label: "YouTube Proxy"
        });
    }

    return (
        <React.Fragment>
            {baseNavItems.map((item) => (
                <Link
                    key={item.path}
                    to={item.path}
                    component={item.path === "/dashboard" ? NavLink : RouterLink}
                    sx={{
                        textDecoration: 'none',
                        color: 'inherit',
                        display: 'block',
                        mb: 0.5,
                    }}
                >
                    <ListItemButton
                        selected={selectedPage === item.path}
                        sx={{
                            borderRadius: '12px',
                            transition: 'all 0.2s ease',
                            '&.Mui-selected': {
                                backgroundColor: `${LIGHT_PURPLE}40`,
                                '&:hover': {
                                    backgroundColor: `${LIGHT_PURPLE}60`,
                                },
                                '& .MuiListItemIcon-root': {
                                    color: LIGHT_PURPLE,
                                },
                                '& .MuiListItemText-primary': {
                                    color: LIGHT_PURPLE,
                                    fontWeight: 600,
                                },
                            },
                            '&:hover': {
                                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                transform: 'translateX(4px)',
                            },
                        }}
                    >
                        <ListItemIcon
                            sx={{
                                color: 'rgba(255, 255, 255, 0.7)',
                                minWidth: 40,
                                transition: 'color 0.2s ease',
                            }}
                        >
                            {item.icon}
                        </ListItemIcon>
                        <ListItemText
                            primary={item.label}
                            primaryTypographyProps={{
                                fontSize: '0.95rem',
                                fontWeight: 500,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap'
                            }}
                        />
                    </ListItemButton>
                </Link>
            ))}
        </React.Fragment>
    );
}

export function SecondaryListItems() {
    return (
        <React.Fragment>
            {/* Secondary items can be added here with the same styling */}
        </React.Fragment>
    );
}
