import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { UserProvider } from './context/UserContext';
import { NotificationProvider } from './context/NotificationContext';
import { ThemeProvider } from '@mui/material';
import theme from './theme';
import App from './App';
import './i18n';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Router>
            <UserProvider>
                <NotificationProvider>
                    <ThemeProvider theme={theme}>
                        <App />
                    </ThemeProvider>
                </NotificationProvider>
            </UserProvider>
        </Router>
    </React.StrictMode>
);
