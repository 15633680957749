// Schema definitions for reward action types
// This function returns schemas based on the translation function
export const getRewardSchemas = (t) => ({
    TTSReward: {
        displayName: t("reward_form.tts_reward_title") || "TTS Reward",
        unique: true,
        title: {
            type: 'text',
            label: t("reward_form.title_label"),
            required: true,
            readOnly: false,
            helpText: t("reward_form.title_help_text"),
        },
        description: {
            type: 'text',
            label: t("reward_form.description_label"),
            required: false,
            readOnly: false,
            multiline: true,
            helpText: t("reward_form.description_help_text"),
        },
        is_active: {
            type: 'boolean',
            label: t("reward_form.active_label"),
            required: false,
            readOnly: false,
            defaultValue: true,
            helpText: t("reward_form.active_help_text"),
        },
        is_prompt_required: {
            type: 'boolean',
            label: t("reward_form.prompt_required_label"),
            readOnly: true,
            defaultValue: true,
            helpText: t("reward_form.prompt_required_help_text"),
        },
        // skip_queue: {
        //     type: 'boolean',
        //     label: 'Skip Queue',
        //     defaultValue: false,
        // },
    },
});

// Helper function to get display name for action type
export const getActionDisplayName = (schemas, actionClass) => {
    return schemas[actionClass]?.displayName || actionClass;
}; 