import React from "react"
import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import Container from "@mui/material/Container"
import Button from "@mui/material/Button"
import { Link } from "react-router-dom"
import TelegramButton from "../TelegramButton"
import { ScrollLink, ScrollToTopLink, MobileNavMenu } from "../home/ScrollLinks"
import { MenuItem } from "@mui/material"
import { useTranslation } from "react-i18next"
import LanguageSelector from "../LanguageSelector"

export const LIGHT_PURPLE = "#B975FF"

export default function AppHeader({
  showNavLinks = true,
  isAuthenticated = false,
  onTwitchAuth
}) {
  const { t, i18n } = useTranslation()
  console.log(i18n.language)
  const authButton = (
    <Button
      variant="contained"
      onClick={onTwitchAuth}
      startIcon={
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: { xs: 20, sm: 24 },
            height: { xs: 20, sm: 24 },
            "& img": {
              width: { xs: "16px", sm: "20px" },
              height: { xs: "16px", sm: "20px" }
            }
          }}
        >
          <img src="/twitch_icon.png" alt="Twitch" />
        </Box>
      }
      sx={{
        backgroundColor: LIGHT_PURPLE,
        color: "white",
        px: { xs: 1.5, sm: 3 },
        py: { xs: 0.75, sm: 1 },
        minWidth: { xs: "auto", sm: "140px" },
        borderRadius: "12px",
        textTransform: "none",
        fontSize: { xs: "0.85rem", sm: "0.95rem" },
        fontWeight: 500,
        "&:hover": {
          backgroundColor: "#9B5FE3",
          transform: "translateY(-2px)",
          boxShadow: "0 4px 12px rgba(185, 117, 255, 0.3)"
        },
        transition: "all 0.3s ease"
      }}
    >
      <Box sx={{ display: { xs: "none", sm: "block" } }}>
        {isAuthenticated ? t("appHeader.dashboard_button") : t("appHeader.login_button")}
      </Box>
      <Box sx={{ display: { xs: "block", sm: "none" } }}>
        {isAuthenticated ? t("appHeader.dashboard_button_mobile") : t("appHeader.login_button_mobile")}
      </Box>
    </Button>
  )

  const navItems = [
    { label: t("appHeader.features_header"), href: "#features" },
    { label: t("appHeader.voices_header"), href: "#voices" },
    { label: t("appHeader.pricing_header"), href: "#pricing" },
    { label: t("appHeader.faq_header"), href: "#faq" }
  ]

  return (
    <>
      <AppBar
        position="fixed"
        elevation={0}
        sx={{
          background: "rgba(31, 32, 37, 0.8)",
          backdropFilter: "blur(10px)",
          borderBottom: "1px solid rgba(255, 255, 255, 0.1)"
        }}
      >
        <Container maxWidth="lg">
          <Toolbar
            sx={{
              py: { xs: 0.5, sm: 1 },
              gap: { xs: 1, sm: 2 },
              justifyContent: "space-between",
              minHeight: { xs: "56px", sm: "64px" }
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: { xs: 1, sm: 2 },
                flex: 1
              }}
            >
              <ScrollToTopLink
                to="/"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: { xs: 1, sm: 2 },
                  textDecoration: "none",
                  color: "inherit",
                  cursor: "pointer",
                  "&:hover": {
                    "& img": {
                      transform: "scale(1.05)"
                    },
                    "& .logo-text": {
                      color: LIGHT_PURPLE
                    }
                  }
                }}
              >
                <img
                  src="/logo_nobg.png"
                  alt="logo"
                  style={{
                    width: "32px",
                    height: "32px",
                    transition: "transform 0.3s ease"
                  }}
                />
                <Typography
                  className="logo-text"
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    transition: "color 0.3s ease",
                    display: { xs: "none", sm: "block" },
                    fontSize: { sm: "1.15rem", md: "1.25rem" }
                  }}
                >
                  JeetBot
                </Typography>
              </ScrollToTopLink>

              {showNavLinks && (
                <Box
                  sx={{
                    display: { xs: "none", md: "flex" },
                    gap: 3,
                    ml: 4
                  }}
                >
                  {navItems.map((item) => (
                    <ScrollLink
                      key={item.label}
                      to={item.href}
                      style={{
                        textDecoration: "none",
                        cursor: "pointer"
                      }}
                    >
                      <Typography
                        sx={{
                          color: "text.secondary",
                          fontSize: "0.95rem",
                          fontWeight: 500,
                          "&:hover": {
                            color: LIGHT_PURPLE
                          },
                          transition: "color 0.2s ease"
                        }}
                      >
                        {item.label}
                      </Typography>
                    </ScrollLink>
                  ))}
                </Box>
              )}
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: { xs: 1, sm: 2 }
              }}
            >
              <TelegramButton />
              {authButton}
              <LanguageSelector />
              {showNavLinks && (
                <Box sx={{ display: { xs: "block", md: "none" } }}>
                  <MobileNavMenu items={navItems} />
                </Box>
              )}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Toolbar /> {/* Spacer for fixed AppBar */}
    </>
  )
}
