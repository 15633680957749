import React from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CustomAudioPlayer from './CustomAudioPlayer';
import { useTranslation } from 'react-i18next';
const LIGHT_PURPLE = '#B975FF';

const ExampleVoices = () => {
    const { t } = useTranslation();

    return (
        <Box sx={{ py: 12 }}>
            <Typography
                variant="h3"
                align="center"
                gutterBottom
                sx={{
                mb: 6,
                fontWeight: 'bold',
                background: 'linear-gradient(45deg, #B975FF, #8A2BE2)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
            }}
        >
            {t("homePage.example_voices_header")}
        </Typography>
        <Grid
            container
            spacing={4}
            justifyContent="center"
            sx={{
                maxWidth: 'none',
                width: '100%',
                mx: 0,
            }}
        >
            {[
                {
                    name: t("homePage.example_voices_name_1"),
                    audioSrc: "https://docs.jeetbot.cc/voice_examples/alt_for_skuf/an_alt_girl_dlc_sunboy_k.mp3"
                },
                {
                    name: t("homePage.example_voices_name_2"),
                    audioSrc: "https://docs.jeetbot.cc/voice_examples/dota2/dota2_pudge.mp3"
                },
                {
                    name: t("homePage.example_voices_name_3"),
                    audioSrc: "https://docs.jeetbot.cc/voice_examples/stalker_sidor.mp3"
                },
            ].map((voice) => (
                <Grid item xs={12} sm={6} md={4} key={voice.name}>
                    <Card
                        sx={{
                            bgcolor: 'rgba(31, 32, 37, 0.8)',
                            backdropFilter: 'blur(10px)',
                            border: '1px solid rgba(185, 117, 255, 0.1)',
                            borderRadius: '16px',
                            '&:hover': {
                                borderColor: 'rgba(185, 117, 255, 0.3)',
                                transform: 'translateY(-2px)',
                                boxShadow: '0 8px 16px rgba(185, 117, 255, 0.1)',
                            },
                            transition: 'all 0.3s ease',
                        }}
                    >
                        <CardContent>
                            <Typography
                                variant="h6"
                                gutterBottom
                                sx={{
                                    color: LIGHT_PURPLE,
                                    fontWeight: 'bold',
                                    mb: 2,
                                }}
                            >
                                {voice.name}
                            </Typography>
                            <CustomAudioPlayer audioSrc={voice.audioSrc} />
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </Grid>
        </Box>
    );
};

export default ExampleVoices; 