import React from 'react';
import { Button, Box } from '@mui/material';
import { FaTelegram } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

const TelegramButton = () => {
  const { t } = useTranslation();

  return (
    <Button
      variant="outlined"
      href="https://jeetbot.cc/tg"
      target="_blank"
      rel="noopener noreferrer"
      sx={{
        borderColor: '#00D1FF',
        color: '#00D1FF',
        textTransform: 'none',
        px: { xs: 1.5, sm: 3 },
        py: { xs: 0.75, sm: 1 },
        minWidth: { xs: 'auto', sm: '140px' },
        borderRadius: '12px',
        '&:hover': {
          borderColor: '#00D1FF',
          backgroundColor: 'rgba(0, 209, 255, 0.1)',
          transform: 'translateY(-2px)',
          boxShadow: '0 5px 15px rgba(0, 209, 255, 0.2)',
        },
        '&:active': {
          transform: 'translateY(-1px)',
          boxShadow: '0 2px 8px rgba(0, 209, 255, 0.2)',
        },
        transition: 'all 0.3s ease',
        display: 'flex',
        alignItems: 'center',
        gap: { xs: 1, sm: 1.5 },
        fontSize: { xs: '0.85rem', sm: '0.95rem' },
        fontWeight: 500,
      }}
      startIcon={
        <FaTelegram 
          style={{ 
            fontSize: '1.2em',
            marginRight: 0
          }} 
        />
      }
    >
      <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
        {t("telegramButton.news")}
      </Box>
      <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
        {t("telegramButton.news_mobile")}
      </Box>
    </Button>
  );
};

export default TelegramButton;
