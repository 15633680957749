import React from 'react';
import { Select, MenuItem, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  
  return (
    <Box sx={{ display: "flex", gap: { xs: 1, sm: 2 } }}>
      <Select
        value={i18n.language && i18n.language.substring(0, 2)}
        onChange={(e) => {
          if (typeof i18n.changeLanguage === 'function') {
            i18n.changeLanguage(e.target.value);
          } else {
            window.location.search = `?lng=${e.target.value}`;
          }
        }}
        size="small"
        sx={{
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none'
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            border: 'none'
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: 'none'
          },
          minWidth: '70px'
        }}
      >
        <MenuItem value="ru">RU</MenuItem>
        <MenuItem value="en">EN</MenuItem>
      </Select>
    </Box>
  );
};

export default LanguageSelector; 