import React, { useState, useCallback } from "react"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import Card from "@mui/material/Card"
import CardHeader from "@mui/material/CardHeader"
import CardContent from "@mui/material/CardContent"
import Button from "@mui/material/Button"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import {FaTelegram} from "react-icons/fa";

const LIGHT_PURPLE = "#B975FF"
const LIGHT_PURPLE_HOVER = "rgba(185, 117, 255, 0.2)"

const Features = () => {
  const { t, i18n } = useTranslation()
  const [hoveredFeature, setHoveredFeature] = useState(null)

  const handleMouseEnter = useCallback((index) => setHoveredFeature(index), [])
  const handleMouseLeave = useCallback(() => setHoveredFeature(null), [])

  return (
    <Box
      sx={{ py: { xs: 8, sm: 12 }, position: "relative", px: { xs: 2, sm: 0 } }}
    >
      <Typography
        variant="h3"
        align="center"
        gutterBottom
        sx={{
          mb: { xs: 4, sm: 6 },
          fontWeight: "bold",
          fontSize: { xs: "2rem", sm: "2.5rem", md: "3rem" },
          background: "linear-gradient(45deg, #B975FF, #8A2BE2)",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent"
        }}
      >
        {t("features.header")}
      </Typography>
      <Grid
        container
        spacing={{ xs: 2, sm: 4 }}
        justifyContent="center"
        sx={{
          maxWidth: "none",
          width: "100%",
          mx: 0,
          position: "relative",
          zIndex: 1
        }}
      >
        {[
          {
            name: t("features.speechSynthesis.title"),
            description: t("features.speechSynthesis.description"),
            docs_link: "https://docs.jeetbot.cc/category/text-to-speech"
          },
          {
            name: "Punto Switcher",
            description:
              "Pf,skb cvtybnm hfcrkflre/ Ntgthm 'nj cjdctv yt ghj,ktvf!",
            hoverText:
              "Забыли сменить раскладку? Теперь это совсем не проблема!",
            docs_link: "https://docs.jeetbot.cc/"
          },
          {
            name: t("features.currencyConverter.title"),
            description: t("features.currencyConverter.description"),
            docs_link:
              "https://docs.jeetbot.cc/currency-converter/automatic-conversion"
          },
          {
            name: t("features.shazam.title"),
            description: t("features.shazam.description"),
            docs_link: "https://docs.jeetbot.cc/"
          },
          {
            name: t("features.translator.title"),
            description: t("features.translator.description"),
            hoverText:
              "Bot can automatically translate messages in your chat to any language",
            docs_link: "https://docs.jeetbot.cc/"
          },
          {
            name: t("features.muchMore.title"),
            description: t("features.muchMore.description"),
            docs_link: "https://docs.jeetbot.cc/",
            buttonText: t("features.button.openDoc"),
          }
        ].map((feature, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            key={feature.name}
            sx={{ mb: { xs: 1, sm: 2 } }}
          >
            <Card
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                bgcolor: "rgba(31, 32, 37, 0.8)",
                backdropFilter: "blur(10px)",
                border: "1px solid rgba(185, 117, 255, 0.1)",
                "&:hover": {
                  transform: { xs: "none", sm: "translateY(-5px)" },
                  boxShadow: `0 10px 20px rgba(185, 117, 255, 0.2)`,
                  borderColor: "rgba(185, 117, 255, 0.3)"
                },
                "&:active": {
                  transform: { xs: "translateY(-2px)", sm: "translateY(-5px)" },
                  boxShadow: `0 5px 10px rgba(185, 117, 255, 0.2)`
                },
                transition: "all 0.3s ease-in-out",
                borderRadius: "16px"
              }}
              onTouchStart={() => handleMouseEnter(index)}
              onTouchEnd={() => handleMouseLeave()}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
            >
              <CardHeader
                title={feature.name}
                titleTypographyProps={{
                  variant: "h6",
                  sx: {
                    fontSize: { xs: "1.1rem", sm: "1.25rem" }
                  }
                }}
                sx={{
                  textAlign: "left",
                  pb: 0,
                  px: { xs: 2, sm: 3 }
                }}
              />
              <CardContent
                sx={{
                  flexGrow: 1,
                  pt: 1,
                  pb: "16px !important",
                  px: { xs: 2, sm: 3 },
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                  minHeight: { xs: "150px", sm: "180px" }
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    flexGrow: 1,
                    mb: { xs: 3, sm: 4 }
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      transition: "opacity 0.3s ease-in-out",
                      opacity:
                        hoveredFeature === index && feature.hoverText ? 0 : 1,
                      zIndex: 1,
                      fontSize: { xs: "0.9rem", sm: "1rem" },
                      lineHeight: { xs: 1.4, sm: 1.6 }
                    }}
                  >
                    {feature.description}
                  </Typography>
                  {feature.hoverText && (
                    <Typography
                      variant="body1"
                      sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        transition: "opacity 0.3s ease-in-out",
                        opacity: hoveredFeature === index ? 1 : 0,
                        zIndex: 1,
                        fontSize: { xs: "0.9rem", sm: "1rem" },
                        lineHeight: { xs: 1.4, sm: 1.6 }
                      }}
                    >
                      {feature.hoverText}
                    </Typography>
                  )}
                </Box>
                <Link
                  to={feature.docs_link}
                  style={{
                    textDecoration: "none",
                    width: "100%",
                    marginTop: "auto"
                  }}
                >
                  <Button
                    fullWidth
                    sx={{
                      color: LIGHT_PURPLE,
                      borderRadius: "8px",
                      "&:hover": {
                        backgroundColor: LIGHT_PURPLE_HOVER,
                        transform: "translateY(-2px)",
                        boxShadow: `0 5px 15px ${LIGHT_PURPLE_HOVER}`
                      },
                      "&:active": {
                        transform: "translateY(-1px)"
                      },
                      transition: "all 0.2s ease",
                      textTransform: "none",
                      fontSize: { xs: "0.9rem", sm: "1rem" },
                      py: { xs: 1, sm: 1.5 },
                      px: { xs: 2, sm: 3 }
                    }}
                  >
                    {feature.buttonText || t("common.more")}
                  </Button>
                </Link>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
        {/*<Button*/}
        {/*    variant="outlined"*/}
        {/*    href=""*/}
        {/*    target="_blank"*/}
        {/*    rel="noopener noreferrer"*/}
        {/*    sx={{*/}
        {/*        backgroundColor: "transparent",*/}
        {/*        border: `2px solid ${LIGHT_PURPLE}`,*/}
        {/*        color: LIGHT_PURPLE,*/}
        {/*        "&:hover": {*/}
        {/*            backgroundColor: "rgba(185, 117, 255, 0.08)",*/}
        {/*            transform: "translateY(-2px)",*/}
        {/*            border: `2px solid ${LIGHT_PURPLE}`*/}
        {/*        },*/}
        {/*        transition: "all 0.3s ease",*/}
        {/*        fontSize: { xs: "1rem", sm: "1.2rem" },*/}
        {/*        py: { xs: 1.5, sm: 1.8 },*/}
        {/*        px: { xs: 3, sm: 4 },*/}
        {/*        borderRadius: "30px",*/}
        {/*        width: { xs: "100%", sm: "auto" },*/}
        {/*        maxWidth: { xs: "300px", sm: "none" },*/}
        {/*        textTransform: "none",*/}
        {/*        fontWeight: "bold",*/}
        {/*        display: "flex",*/}
        {/*        gap: "8px",*/}
        {/*        alignItems: "center"*/}
        {/*    }}*/}
        {/*    startIcon={*/}
        {/*        <FaTelegram*/}
        {/*            style={{*/}
        {/*                fontSize: '1.2em',*/}
        {/*                marginRight: 0*/}
        {/*            }}*/}
        {/*        />*/}
        {/*    }*/}
        {/*></Button>*/}
    </Box>
  )
}

export default Features
