import * as React from 'react';
import { useState, useEffect } from 'react';
import {
    Box,
    Breadcrumbs,
    Button,
    Card,
    CardContent,
    Container,
    Grid,
    Link,
    Typography,
    IconButton,
    Tooltip,
    Chip,
    Stack,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import Requester from '../../../utils/requester';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { useNotification } from '../../../context/NotificationContext';
import { useUser } from '../../../context/UserContext';
import StarIcon from '@mui/icons-material/Star';
import { useTranslation } from 'react-i18next';

const MAX_VOICES = 2;

export default function VoicesList() {
    const { showNotification } = useNotification();
    const { hasFeatureAccess } = useUser();
    const [voices, setVoices] = useState([]);
    const [loading, setLoading] = useState(true);
    const [deletingVoices, setDeletingVoices] = useState(new Set());
    const { t } = useTranslation();
    useEffect(() => {
        const fetchVoices = async () => {
            try {
                const response = await Requester.get('tts/elevenlabs/voices/');
                if (response.status === 200) {
                    const data = await response.json();
                    setVoices(data);
                }
            } catch (error) {
                console.error('Ошибка при загрузке голосов:', error);
                showNotification('Не удалось загрузить список голосов', {
                    type: 'error',
                    autoHideDuration: 3000
                });
            } finally {
                setLoading(false);
            }
        };

        fetchVoices();
    }, [showNotification]);

    const handleDelete = async (id) => {
        if (deletingVoices.has(id)) return;
        
        setDeletingVoices(prev => new Set([...prev, id]));
        try {
            const response = await Requester.delete(`tts/elevenlabs/voices/${id}/`);
            if (response.status === 204 || response.status === 200) {
                const refreshResponse = await Requester.get('tts/elevenlabs/voices/');
                if (refreshResponse.status === 200) {
                    const data = await refreshResponse.json();
                    setVoices(data);
                }
                showNotification('Голос успешно удален', {
                    type: 'success',
                    autoHideDuration: 3000
                });
            }
        } catch (error) {
            console.error('Ошибка при удалении голоса:', error);
            showNotification('Не удалось удалить голос', {
                type: 'error',
                autoHideDuration: 3000
            });
        } finally {
            setDeletingVoices(prev => {
                const next = new Set(prev);
                next.delete(id);
                return next;
            });
        }
    };

    return (
        <Box sx={{ minHeight: '100vh', pb: 8 }}>
            <Container maxWidth="lg" sx={{ pt: 4 }}>
                {/* Header Section */}
                <Box sx={{ mb: 4 }}>
                    <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
                        <Link
                            underline="hover"
                            color="inherit"
                            to="/dashboard"
                            component={RouterLink}
                            sx={{ '&:hover': { color: 'primary.main' } }}
                        >
                            {t("voices_list.home")}
                        </Link>
                        <Typography color="primary">{t("voices_list.voices_title")}</Typography>
                    </Breadcrumbs>

                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 3 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            <RecordVoiceOverIcon sx={{ fontSize: 32, color: 'primary.main' }} />
                            <Typography variant="h4" fontWeight="500">
                                {t("voices_list.voices_title")}
                            </Typography>
                        </Box>
                        {hasFeatureAccess('voiceover_elevenlabs_custom') && (
                            <Button
                                variant="contained"
                                component={RouterLink}
                                to={voices.length >= MAX_VOICES ? '#' : "/dashboard/voices/new"}
                                startIcon={<AddIcon />}
                                disabled={voices.length >= MAX_VOICES}
                                onClick={(e) => {
                                    if (voices.length >= MAX_VOICES) {
                                        e.preventDefault();
                                        showNotification(`Вы можете создать максимум ${MAX_VOICES} голоса. Удалите существующий голос, чтобы создать новый.`, {
                                            type: 'warning',
                                            autoHideDuration: 5000
                                        });
                                    }
                                }}
                            >
                                Создать голос
                            </Button>
                        )}
                    </Box>
                </Box>

                <Grid container spacing={2}>
                    {loading ? (
                        <Grid item xs={12}>
                            <Typography>{t("voices_list.loading_voices")}</Typography>
                        </Grid>
                    ) : !hasFeatureAccess('voiceover_elevenlabs_custom') ? (
                        <Grid item xs={12}>
                            <Card
                                elevation={0}
                                sx={{
                                    borderRadius: 2,
                                    border: '1px solid',
                                    borderColor: 'divider',
                                    bgcolor: 'background.paper',
                                }}
                            >
                                <CardContent sx={{ textAlign: 'center', py: 6 }}>
                                    <RecordVoiceOverIcon sx={{ fontSize: 48, color: 'text.secondary', mb: 2 }} />
                                    <Typography variant="h6" color="text.secondary" gutterBottom>
                                        {t("voices_list.function_unavailable")}
                                    </Typography>
                                    <Typography color="text.secondary" sx={{ mb: 3 }}>
                                        {t("voices_list.function_unavailable_description")}
                                    </Typography>
                                    <Button
                                        variant="contained"
                                        component={RouterLink}
                                        to="/dashboard"
                                        startIcon={<StarIcon />}
                                    >
                                        {t("voices_list.upgrade_subscription")}
                                    </Button>
                                </CardContent>
                            </Card>
                        </Grid>
                    ) : voices.length === 0 ? (
                        <Grid item xs={12}>
                            <Card
                                elevation={0}
                                sx={{
                                    borderRadius: 2,
                                    border: '1px solid',
                                    borderColor: 'divider',
                                    bgcolor: 'background.paper',
                                }}
                            >
                                <CardContent sx={{ textAlign: 'center', py: 6 }}>
                                    <RecordVoiceOverIcon sx={{ fontSize: 48, color: 'text.secondary', mb: 2 }} />
                                    <Typography variant="h6" color="text.secondary" gutterBottom>
                                        Нет созданных голосов
                                    </Typography>
                                    <Typography color="text.secondary" sx={{ mb: 3 }}>
                                        Создайте свой первый голос
                                    </Typography>
                                    <Button
                                        variant="contained"
                                        component={RouterLink}
                                        to={voices.length >= MAX_VOICES ? '#' : "/dashboard/voices/new"}
                                        startIcon={<AddIcon />}
                                        disabled={voices.length >= MAX_VOICES}
                                        onClick={(e) => {
                                            if (voices.length >= MAX_VOICES) {
                                                e.preventDefault();
                                                showNotification(`Вы можете создать максимум ${MAX_VOICES} голоса. Удалите существующий голос, чтобы создать новый.`, {
                                                    type: 'warning',
                                                    autoHideDuration: 5000
                                                });
                                            }
                                        }}
                                    >
                                        Создать голос
                                    </Button>
                                </CardContent>
                            </Card>
                        </Grid>
                    ) : (
                        voices.map((voice) => (
                            <Grid item xs={12} key={voice.id}>
                                <Card
                                    elevation={0}
                                    sx={{
                                        borderRadius: 2,
                                        border: '1px solid',
                                        borderColor: 'divider',
                                        '&:hover': {
                                            borderColor: 'primary.main',
                                            boxShadow: '0 0 0 1px rgba(185, 117, 255, 0.2)',
                                        },
                                    }}
                                >
                                    <CardContent>
                                        <Grid container alignItems="center" spacing={2}>
                                            <Grid item xs={12} sm={5}>
                                                <Tooltip title={`Используйте команду !tts ${voice.voice_name} для выбора этого голоса`} arrow>
                                                    <Typography variant="h6" component="div">
                                                        {voice.voice_name}
                                                    </Typography>
                                                </Tooltip>
                                                <Typography variant="body2" color="text.secondary">
                                                    Канал: {voice.channel_name}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={5}>
                                                <Typography variant="body2" color="text.secondary" sx={{ mb: 0.5 }}>
                                                    Стабильность: {voice.stability}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary" sx={{ mb: 0.5 }}>
                                                    Схожесть: {voice.similarity_boost}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    Стиль: {voice.style}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                                                    {hasFeatureAccess('voiceover_elevenlabs_custom') && (
                                                        <>
                                                            <Tooltip title="Редактировать">
                                                                <IconButton
                                                                    component={RouterLink}
                                                                    to={`/dashboard/voices/${voice.id}/edit`}
                                                                    size="small"
                                                                    disabled={deletingVoices.has(voice.id)}
                                                                    sx={{
                                                                        color: 'primary.main',
                                                                        '&:hover': { backgroundColor: 'rgba(185, 117, 255, 0.1)' },
                                                                    }}
                                                                >
                                                                    <EditIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title="Удалить">
                                                                <IconButton
                                                                    onClick={() => handleDelete(voice.id)}
                                                                    size="small"
                                                                    disabled={deletingVoices.has(voice.id)}
                                                                    sx={{
                                                                        color: 'error.main',
                                                                        '&:hover': { backgroundColor: 'rgba(211, 47, 47, 0.1)' },
                                                                    }}
                                                                >
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </>
                                                    )}
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))
                    )}
                </Grid>
            </Container>
        </Box>
    );
} 
