import * as React from 'react';
import { useState, useEffect } from 'react';
import {
    Box,
    Breadcrumbs,
    Button,
    Card,
    CardContent,
    Container,
    Grid,
    Link,
    TextField,
    Typography,
    FormControlLabel,
    Switch,
    Alert,
    Tooltip,
} from '@mui/material';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import Requester from '../../../utils/requester';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { useNotification } from '../../../context/NotificationContext';
import CustomSelect from '../../../components/CustomSelect';
import { useTranslation } from 'react-i18next';
import { getRewardSchemas, getActionDisplayName } from './reward-schemas';

export default function RewardForm() {
    const { id } = useParams();
    const navigate = useNavigate();
    const { showNotification } = useNotification();
    const isEditing = id !== undefined;
    const { t } = useTranslation();
    
    // Get schemas using the translation function
    const ACTION_SCHEMAS = getRewardSchemas(t);
    
    const [formData, setFormData] = useState({
        action_class: '',
        title: '',
        cost: 10,
        is_prompt_required: true,
        skip_queue: false,
        kwargs: '{}',
        description: '',
        is_active: true,
    });
    const [loading, setLoading] = useState(false);
    const [currentSchema, setCurrentSchema] = useState(null);
    const [existingRewards, setExistingRewards] = useState([]);

    useEffect(() => {
        // Fetch existing rewards when component mounts
        const fetchExistingRewards = async () => {
            try {
                const response = await Requester.get('channel/rewards/');
                if (response.status === 200) {
                    const data = await response.json();
                    setExistingRewards(data);
                }
            } catch (error) {
                console.error('Error fetching existing rewards:', error);
            }
        };

        fetchExistingRewards();
    }, []);

    useEffect(() => {
        const fetchRewardData = async () => {
            if (!isEditing) return;
            
            try {
                const response = await Requester.get(`channel/rewards/${id}/`);
                if (response.status === 200) {
                    const data = await response.json();
                    setFormData({
                        title: data.title,
                        action_class: data.action_class,
                        is_prompt_required: data.is_prompt_required,
                        skip_queue: data.skip_queue,
                        kwargs: JSON.stringify(data.kwargs || {}),
                        cost: data.cost || 0,
                        description: data.description || '',
                        is_active: data.is_active !== undefined ? data.is_active : true,
                    });
                    updateSchema(data.action_class);
                }
            } catch (error) {
                showNotification(t("reward_form.error_loading_reward_data"), {
                    type: 'error',
                    autoHideDuration: 3000
                });
            }
        };

        fetchRewardData();
    }, [id, isEditing, showNotification]);

    const updateSchema = (actionClass) => {
        const schema = ACTION_SCHEMAS[actionClass];
        if (schema) {
            setCurrentSchema(schema);
            // Apply default values from schema
            const defaultValues = {};
            Object.entries(schema).forEach(([field, config]) => {
                if (field !== 'displayName' && field !== 'unique' && config.defaultValue !== undefined && !formData[field]) {
                    defaultValues[field] = config.defaultValue;
                }
            });
            if (Object.keys(defaultValues).length > 0) {
                setFormData(prev => ({ ...prev, ...defaultValues }));
            }
        } else {
            setCurrentSchema(null);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (loading) return;

        // Check if action type is selected
        if (!isEditing && !formData.action_class) {
            showNotification(t("reward_form.please_select_action_type"), {
                type: 'error',
                autoHideDuration: 3000
            });
            return;
        }

        // Check for uniqueness if required by schema
        if (!isEditing && currentSchema?.unique) {
            const existingReward = existingRewards.find(
                reward => reward.action_class === formData.action_class
            );
            
            if (existingReward) {
                showNotification(t("reward_form.reward_already_exists"), {
                    type: 'error',
                    autoHideDuration: 3000
                });
                return;
            }
        }

        setLoading(true);
        try {
            let response;
            const submitData = {
                ...formData,
                kwargs: JSON.parse(formData.kwargs || '{}'),
            };

            if (isEditing) {
                // Only send the fields that can be edited
                const editableData = {
                    title: formData.title,
                    cost: formData.cost,
                    description: formData.description,
                    is_active: formData.is_active,
                };
                response = await Requester.patch(`channel/rewards/${id}/`, editableData);
            } else {
                response = await Requester.post('channel/rewards/', submitData);
            }

            if (response.status === 200 || response.status === 201) {
                showNotification(t(`reward_form.reward_${isEditing ? 'updated' : 'created'}_successfully`), {
                    type: 'success',
                    autoHideDuration: 3000
                });
                navigate('/dashboard/rewards');
            }
        } catch (error) {
            console.error('Error saving reward:', error);
            showNotification(t("reward_form.error_saving_reward"), {
                type: 'error',
                autoHideDuration: 3000
            });
        } finally {
            setLoading(false);
        }
    };

    const handleChange = (field) => (event) => {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        setFormData(prev => ({ ...prev, [field]: value }));
    };

    const handleActionClassChange = (value) => {
        setFormData(prev => ({ ...prev, action_class: value }));
        updateSchema(value);
    };

    const renderFormField = (fieldName, config) => {
        if (fieldName === 'displayName' || fieldName === 'unique') return null;
        
        const field = (
            <Grid item xs={12} md={6} key={fieldName}>
                {config.type === 'text' && (
                    <TextField
                        label={config.label}
                        fullWidth
                        value={formData[fieldName] || ''}
                        onChange={handleChange(fieldName)}
                        required={config.required}
                        InputProps={{
                            readOnly: config.readOnly,
                        }}
                        multiline={config.multiline}
                        rows={config.multiline ? 4 : 1}
                    />
                )}
                {config.type === 'boolean' && (
                    <FormControlLabel
                        control={
                            <Switch
                                checked={formData[fieldName] || false}
                                onChange={handleChange(fieldName)}
                                color="primary"
                                disabled={config.readOnly}
                            />
                        }
                        label={config.label}
                    />
                )}
                {config.type === 'number' && (
                    <TextField
                        label={config.label}
                        fullWidth
                        type="number"
                        value={formData[fieldName] || 0}
                        onChange={handleChange(fieldName)}
                        required={config.required}
                        InputProps={{
                            readOnly: config.readOnly,
                            inputProps: {
                                min: config.min || 0,
                                step: config.step || 1,
                            }
                        }}
                    />
                )}
            </Grid>
        );

        return config.helpText ? (
            <Tooltip key={fieldName} title={config.helpText} arrow placement="top">
                {field}
            </Tooltip>
        ) : field;
    };

    // Get available action types from schema
    const availableActions = Object.entries(ACTION_SCHEMAS)
        .filter(([actionClass, schema]) => {
            // If schema is not unique, always include it
            if (!schema.unique) return true;
            // If schema is unique, include only if it doesn't exist yet
            return !existingRewards.some(reward => reward.action_class === actionClass);
        })
        .map(([key, schema]) => ({
            id: key,
            displayName: schema.displayName
        }));

    // Show warning if action type is unique and already exists
    const showUniqueWarning = !isEditing && currentSchema?.unique && 
        existingRewards.some(reward => reward.action_class === formData.action_class);

    // Show message when no actions are available
    const showNoActionsMessage = !isEditing && availableActions.length === 0;

    // Check if form is valid
    const isFormValid = isEditing || (formData.action_class && !showUniqueWarning);

    // Helper function for getting display name
    const getDisplayNameForActionClass = (actionClass) => {
        return actionClass ? getActionDisplayName(ACTION_SCHEMAS, actionClass) : '';
    };

    return (
        <Box sx={{ minHeight: '100vh', pb: 8 }}>
            <Container maxWidth="lg" sx={{ pt: 4 }}>
                {/* Header Section */}
                <Box sx={{ mb: 4 }}>
                    <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
                        <Link
                            underline="hover"
                            color="inherit"
                            to="/dashboard"
                            component={RouterLink}
                            sx={{
                                '&:hover': { color: 'primary.main' }
                            }}
                        >
                            {t("reward_form.home")}
                        </Link>
                        <Link
                            underline="hover"
                            color="inherit"
                            to="/dashboard/rewards"
                            component={RouterLink}
                            sx={{
                                '&:hover': { color: 'primary.main' }
                            }}
                        >
                            {t("reward_form.rewards")}
                        </Link>
                        <Typography color="primary">
                            {isEditing ? t("reward_form.editing_reward") : t("reward_form.creating_reward")}
                        </Typography>
                    </Breadcrumbs>

                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 3 }}>
                        <EmojiEventsIcon sx={{ fontSize: 32, color: 'primary.main' }} />
                        <Typography variant="h4" fontWeight="500">
                            {isEditing ? t("reward_form.editing_reward") : t("reward_form.creating_reward")}
                        </Typography>
                    </Box>
                </Box>

                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Card
                            elevation={0}
                            sx={{
                                borderRadius: 2,
                                border: '1px solid',
                                borderColor: 'divider',
                            }}
                        >
                            <CardContent sx={{ p: 4 }}>
                                {showUniqueWarning && (
                                    <Alert 
                                        severity="warning" 
                                        sx={{ mb: 3 }}
                                    >
                                        {t("reward_form.reward_already_exists")}
                                    </Alert>
                                )}
                                {showNoActionsMessage && (
                                    <Alert 
                                        severity="info" 
                                        sx={{ mb: 3 }}
                                    >
                                        {t("reward_form.no_actions_available")}
                                    </Alert>
                                )}
                                <form onSubmit={handleSubmit}>
                                    <Grid container spacing={3}>
                                        {!isEditing && (
                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item xs={12} md={6}>
                                                        <CustomSelect
                                                            value={getDisplayNameForActionClass(formData.action_class)}
                                                            options={availableActions.map(action => action.displayName)}
                                                            onChange={(displayName) => {
                                                                const action = availableActions.find(a => a.displayName === displayName);
                                                                if (action) {
                                                                    handleActionClassChange(action.id);
                                                                }
                                                            }}
                                                            label={t("reward_form.action_type_label")}
                                                            placeholder={t("reward_form.action_type_placeholder")}
                                                            disabled={loading || availableActions.length === 0}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )}
                                        
                                        {isEditing && (
                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item xs={12} md={6}>
                                                        <TextField
                                                            label={t("reward_form.action_type_label")}
                                                            fullWidth
                                                            value={getDisplayNameForActionClass(formData.action_class)}
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )}
                                        
                                        {(isEditing || formData.action_class) && (
                                            <Grid item xs={12}>
                                                <Grid container spacing={3}>
                                                    {/* Title field */}
                                                    <Grid item xs={12} md={6}>
                                                        <TextField
                                                            label={t("reward_form.title_label")}
                                                            fullWidth
                                                            value={formData.title}
                                                            onChange={handleChange('title')}
                                                            required
                                                        />
                                                    </Grid>

                                                    {/* Cost field */}
                                                    <Grid item xs={12} md={6}>
                                                        <TextField
                                                            label={t("reward_form.cost_label")}
                                                            fullWidth
                                                            type="number"
                                                            value={formData.cost}
                                                            onChange={handleChange('cost')}
                                                            required
                                                            InputProps={{
                                                                inputProps: {
                                                                    min: 0,
                                                                    step: 1,
                                                                }
                                                            }}
                                                        />
                                                    </Grid>

                                                    {/* Description field */}
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            label={t("reward_form.description_label")}
                                                            fullWidth
                                                            multiline
                                                            rows={4}
                                                            value={formData.description}
                                                            onChange={handleChange('description')}
                                                            helperText={t("reward_form.description_help_text")}
                                                        />
                                                    </Grid>

                                                    {/* Active state switch */}
                                                    <Grid item xs={12}>
                                                        <Tooltip title={t("reward_form.active_help_text")} arrow placement="top">
                                                            <FormControlLabel
                                                                control={
                                                                    <Switch
                                                                        checked={formData.is_active}
                                                                        onChange={handleChange('is_active')}
                                                                        color="primary"
                                                                    />
                                                                }
                                                                label={t("reward_form.active_label")}
                                                            />
                                                        </Tooltip>
                                                    </Grid>

                                                    {/* Other fields */}
                                                    {currentSchema && Object.entries(currentSchema)
                                                        .filter(([fieldName]) => fieldName !== 'title' && fieldName !== 'displayName' && fieldName !== 'unique' && fieldName !== 'description' && fieldName !== 'is_active')
                                                        .map(([fieldName, config]) => renderFormField(fieldName, config))
                                                    }
                                                </Grid>
                                            </Grid>
                                        )}

                                        <Grid item xs={12}>
                                            <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
                                                <Button
                                                    variant="outlined"
                                                    onClick={() => navigate('/dashboard/rewards')}
                                                    disabled={loading}
                                                >
                                                    {t("reward_form.cancel")}
                                                </Button>
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    disabled={loading || !isFormValid || (!isEditing && availableActions.length === 0)}
                                                >
                                                    {loading ? t("reward_form.saving") : (isEditing ? t("reward_form.update") : t("reward_form.create"))}
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </form>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
} 