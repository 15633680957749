import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

import * as React from "react";
import Container from "@mui/material/Container";
import {Outlet} from "react-router-dom";
import Box from "@mui/material/Box";
import { useTranslation } from 'react-i18next';

export default function MyCopyright() {
    const { t } = useTranslation();

    return (
        <Container maxWidth="lg">
            {/* We will have here blocks of pages selected by current url*/}
            <Container sx={{
                bottom: 0,
                width: '100%'
            }}>
                <Box sx={{
                    flexGrow: 1,
                    justifyContent: "center",
                    display: "flex",
                }}>
                    <Typography variant="body2" color="text.secondary" align="center">
                        {'JeetBot © '}
                        <Link color="inherit" href="https://alexue4.dev/">
                            alexue4.dev
                        </Link>{' '}
                        {new Date().getFullYear()}
                        {' | '}
                        <Link color="inherit" href="/terms">
                            {t("copyright.terms")}
                        </Link>
                    </Typography>
                </Box>
            </Container>
        </Container>
    );
}