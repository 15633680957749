import React, { useState, useEffect } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../theme";
import MyCopyright from "../modules/common/copyright";
import { ym_hit } from "../utils/yandex";
import { Snackbar } from "@mui/material";
import { useNotification } from '../context/NotificationContext';
import { TWITCH_REDIRECT_URI } from "../constants";
import Requester from "../utils/requester";
import AppHeader from '../components/common/AppHeader';
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

// Import components
import Hero from '../components/home/Hero';
import Features from '../components/home/Features';
import ExampleVoices from '../components/home/ExampleVoices';
import FAQ from '../components/home/FAQ';
import SubscriptionPlans from '../components/home/SubscriptionPlans';
import { LIGHT_PURPLE } from '../components/home/constants';

export default function HomePage() {
    ym_hit('/');
    const { showNotification } = useNotification();
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [cookieConsent, setCookieConsent] = React.useState(() => {
        return localStorage.getItem("cookieConsent") === "accepted";
    });

    const handleAcceptCookies = () => {
        localStorage.setItem("cookieConsent", "accepted");
        setCookieConsent(true);
    };

    // Check authentication status without redirect
    React.useEffect(() => {
        Requester.validateToken().then(result => {
            setIsAuthenticated(result);
        }).catch(error => {
            console.log(error);
            setIsAuthenticated(false);
        });

        // Check for auth error message
        const authError = localStorage.getItem('auth_error');
        if (authError) {
            showNotification(authError, {
                type: 'error',
                autoHideDuration: 6000
            });
            localStorage.removeItem('auth_error');
        }
    }, [showNotification]);

    const handleTwitchAuth = async () => {
        // If already authenticated, just redirect to dashboard
        if (isAuthenticated) {
            window.location.href = '/dashboard';
            return;
        }

        try {
            const response = await Requester.post('twitch/get_twitch_auth_url/', {
                redirect_url: TWITCH_REDIRECT_URI,
            });

            if (response.status !== 200) {
                showNotification('Ошибка при авторизации через Twitch', {
                    type: 'error',
                    autoHideDuration: 3000
                });
                return;
            }

            const data = await response.json();
            window.location.href = data.url;
        } catch (error) {
            console.log(error);
            showNotification('Ошибка при авторизации через Twitch', {
                type: 'error',
                autoHideDuration: 3000
            });
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <Box sx={{display: 'flex', flexDirection: 'column', minHeight: '100vh'}}>
                <AppHeader
                    showNavLinks={true}
                    isAuthenticated={isAuthenticated}
                    onTwitchAuth={handleTwitchAuth}
                />
                <main style={{flex: 1}}>
                    <Hero onTwitchAuth={handleTwitchAuth}/>
                    <Container maxWidth="lg">
                        <Box id="features" sx={{ scrollMarginTop: '80px' }}><Features/></Box>
                        <Box id="voices" sx={{ scrollMarginTop: '80px' }}><ExampleVoices/></Box>
                        <Box id="pricing" sx={{ scrollMarginTop: '80px' }}>
                            <SubscriptionPlans onTwitchAuth={handleTwitchAuth}/>
                        </Box>
                        <Box id="faq" sx={{ scrollMarginTop: '80px' }}><FAQ/></Box>
                    </Container>
                </main>
                <Box sx={{bgcolor: 'background.paper', p: 6}} component="footer">
                    <MyCopyright/>
                </Box>
                <Snackbar
                    open={!cookieConsent}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    sx={{
                        '& .MuiPaper-root': {
                            maxWidth: '600px',
                            backgroundColor: 'rgba(255, 255, 255, 0.95)',
                            backdropFilter: 'blur(10px)',
                            borderRadius: '12px',
                            p: 2.5,
                            boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)',
                            color: '#1F2025',
                        }
                    }}
                    message={
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                            <Typography sx={{ fontSize: '0.95rem' }}>
                                Мы используем файлы cookie для улучшения рабты сайта. Продолжая использовать наш сайт,
                                вы соглашаетесь с нашей {' '}
                                <Link
                                    to="/terms"
                                    style={{
                                        color: LIGHT_PURPLE,
                                        textDecoration: 'underline'
                                    }}
                                >
                                    политикой использования файлов cookie
                                </Link>
                            </Typography>
                        </Box>
                    }
                    action={
                        <Button
                            onClick={handleAcceptCookies}
                            variant="contained"
                            sx={{
                                backgroundColor: LIGHT_PURPLE,
                                color: 'white',
                                '&:hover': {
                                    backgroundColor: '#9B5FE3',
                                },
                                ml: 2,
                                px: 3,
                                py: 1,
                                borderRadius: '8px',
                                textTransform: 'none',
                                fontSize: '0.95rem'
                            }}
                        >
                            Принять
                        </Button>
                    }
                />
            </Box>
            <Container maxWidth="lg">
                <Box>
                    <Typography
                        variant="body2"
                        align="center"
                        sx={{
                            marginTop: '-40px',
                            fontSize: '11px',
                            opacity: 0.2
                        }}
                    >
                        ФИО: Попов Александр Алексеевич
                        ИНН: 742300618999
                        Email: admin@alexue4.dev
                    </Typography>
                    <Typography
                        align="center"
                    >
                        <a href="https://1-2dev.ru/" target="_blank"
                           className="onetwodev">
                            <img src="/12dev.png" alt="Веб-студия РазДваБотка"/>
                        </a>
                    </Typography>
                </Box>
            </Container>
        </ThemeProvider>
    );
}
