import React from "react"
import { Routes, Route } from "react-router-dom"
import CssBaseline from "@mui/material/CssBaseline"
import Dashboard from "./routes/dashboard/dashboard"
import DashIndex from "./routes/dashboard/pages/dash-index"
import SignIn from "./routes/signin"
import PaymentSuccess from "./routes/dashboard/pages/payment-success"
import Terms from "./routes/terms"
import HomePage from "./routes/homePage"
import ErrorPage from "./routes/error-page"
import NotImplementedPage from "./routes/not-implemented"
import CommandList from "./routes/dashboard/pages/command-list"
import AuthRequired from "./modules/auth/AuthRequired"
import ChannelForm from "./routes/dashboard/pages/channel-form"
import TwitchSignIn from "./routes/twitch-signin"
import TwitchProcessAccess from "./routes/twitch-process-access"
import BotProcessAccess from "./routes/bot-process-access"
import TtsSettings from "./routes/dashboard/pages/tts-settings"
import TimerList, { timersLoader } from "./routes/dashboard/pages/timer-list"
import GiftPaymentSuccess from "./routes/gift-payment-success"
import RewardsList from "./routes/dashboard/pages/rewards-list"
import RewardForm from "./routes/dashboard/pages/reward-form"
import CustomVoiceForm from "./routes/dashboard/pages/custom-voice-form"
import VoicesList from "./routes/dashboard/pages/voices-list"
import YoutubeProxy from "./routes/dashboard/pages/youtube-proxy"
import DonationAlertsRedirect from "./routes/donation-alerts-redirect"
import BotManagement from "./routes/dashboard/pages/bot-management"

function App() {
  return (
    <>
      <CssBaseline />
      <Routes>
        <Route path="/" element={<HomePage />} errorElement={<ErrorPage />} />
        <Route path="/terms" element={<Terms />} errorElement={<ErrorPage />} />
        <Route
          path="/sign-in"
          element={<SignIn />}
          errorElement={<ErrorPage />}
        />
        <Route
          path="/twitch-sign-in"
          element={<TwitchSignIn />}
          errorElement={<ErrorPage />}
        />
        <Route
          path="/twitch-auth-callback"
          element={<TwitchProcessAccess />}
          errorElement={<ErrorPage />}
        />
        <Route
          path="/donation_alerts/redirect"
          element={<DonationAlertsRedirect />}
          errorElement={<ErrorPage />}
        />

        <Route element={<AuthRequired />}>
          <Route
            path="/dashboard"
            element={<Dashboard />}
            errorElement={<ErrorPage />}
          >
            <Route index element={<DashIndex />} errorElement={<ErrorPage />} />
            <Route
              path="commands"
              element={<CommandList />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="channel-edit"
              element={<ChannelForm />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="bot-management"
              element={<BotManagement />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="tts-settings"
              element={<TtsSettings />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="timers/list"
              element={<TimerList />}
              loader={timersLoader}
              errorElement={<ErrorPage />}
            />
            <Route path="payment_success" element={<PaymentSuccess />} />
            <Route
              path="rewards"
              element={<RewardsList />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="rewards/new"
              element={<RewardForm />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="rewards/:id/edit"
              element={<RewardForm />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="voices"
              element={<VoicesList />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="voices/new"
              element={<CustomVoiceForm />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="voices/:id/edit"
              element={<CustomVoiceForm />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="youtube-proxy"
              element={<YoutubeProxy />}
              errorElement={<ErrorPage />}
            />
          </Route>
        </Route>
        <Route
          path="/gift-payment-success"
          element={<GiftPaymentSuccess />}
          errorElement={<ErrorPage />}
        />
      </Routes>
    </>
  )
}

export default App
