import * as React from 'react';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MyCopyright from "../../modules/common/copyright";
import { MainListItems, SecondaryListItems } from "./navigation";
import { Outlet, useNavigate } from "react-router-dom";
import { AccountCircle } from "@mui/icons-material";
import { Menu, MenuItem, Box, List } from "@mui/material";
import { ym_hit } from "../../utils/yandex";
import { useUser } from "../../context/UserContext";
import TelegramButton from '../../components/TelegramButton';
import NotificationsButton from '../../components/NotificationsButton';
import { Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import LanguageSelector from '../../components/LanguageSelector';

const LIGHT_PURPLE = '#B975FF';
const drawerWidth = 280;

const AppBar = styled(MuiAppBar)(({ theme }) => ({
    background: 'rgba(31, 32, 37, 0.8)',
    backdropFilter: 'blur(10px)',
    borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
    boxShadow: 'none',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        width: `100%`,
        marginLeft: theme.spacing(9),
    },
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'fixed',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            height: '100vh',
            background: 'rgba(31, 32, 37, 0.95)',
            borderRight: '1px solid rgba(255, 255, 255, 0.1)',
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);

const MainContent = styled(Box)({
    backgroundColor: '#1F2025',
    flexGrow: 1,
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
});

export default function Dashboard() {
    const { t, i18n } = useTranslation();
    const [open, setOpen] = React.useState(true);
    const { user } = useUser();
    const navigate = useNavigate();
    const toggleDrawer = () => {
        setOpen(!open);
    };
    const [anchorEl, setAnchorEl] = React.useState(null);
    const menuOpen = Boolean(anchorEl);
    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const menuLogout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
        window.location.href = "/";
    }
    const handleNavigateHome = () => {
        navigate('/');
        handleClose();
    };
    ym_hit('/dashboard');

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed">
                <Toolbar
                    sx={{
                        pr: '24px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        minHeight: { xs: 48, sm: 48 },
                        py: 0.5
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <IconButton
                            edge="start"
                            onClick={toggleDrawer}
                            sx={{
                                ...(open && { display: 'none' }),
                                color: 'white',
                                '&:hover': {
                                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                },
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Box
                            component={Link}
                            href="/"
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 2,
                                textDecoration: 'none',
                                color: 'inherit',
                                '&:hover': {
                                    '& img': {
                                        transform: 'scale(1.05)',
                                    },
                                    '& .logo-text': {
                                        color: LIGHT_PURPLE,
                                    },
                                },
                            }}
                        >
                            <img
                                src="/logo_nobg.png"
                                alt="logo"
                                style={{
                                    width: '40px',
                                    height: '40px',
                                    transition: 'transform 0.3s ease',
                                }}
                            />
                            <Typography
                                className="logo-text"
                                variant="h6"
                                sx={{
                                    fontWeight: 'bold',
                                    transition: 'color 0.3s ease',
                                    display: { xs: 'none', sm: 'block' },
                                }}
                            >
                                JeetBot
                            </Typography>
                        </Box>
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <TelegramButton />
                        <NotificationsButton />
                        <LanguageSelector />
                        <IconButton
                            size="large"
                            edge="end"
                            aria-label="account of current user"
                            aria-controls={menuOpen ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                            sx={{
                                color: 'white',
                                '&:hover': {
                                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                },
                            }}
                        >
                            <AccountCircle />
                        </IconButton>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={menuOpen}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                            PaperProps={{
                                sx: {
                                    backgroundColor: 'rgba(31, 32, 37, 0.95)',
                                    backdropFilter: 'blur(10px)',
                                    border: '1px solid rgba(255, 255, 255, 0.1)',
                                    borderRadius: '12px',
                                    mt: 1,
                                    minWidth: '180px',
                                    '& .MuiMenuItem-root': {
                                        fontSize: '0.95rem',
                                        py: 1.5,
                                        px: 2,
                                        transition: 'all 0.2s ease',
                                        '&:hover': {
                                            backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                            paddingLeft: '20px',
                                        },
                                    },
                                }
                            }}
                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        >
                            <MenuItem onClick={handleNavigateHome}>{t("dashboard.home")}</MenuItem>
                            <MenuItem onClick={menuLogout}>{t("dashboard.logout")}</MenuItem>
                        </Menu>
                    </Box>
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open}>
                <Toolbar
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        px: [1],
                        py: 0.5,
                        minHeight: { xs: 48, sm: 48 }
                    }}
                >
                    <IconButton
                        onClick={toggleDrawer}
                        sx={{
                            color: 'white',
                            '&:hover': {
                                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                            },
                        }}
                    >
                        <ChevronLeftIcon />
                    </IconButton>
                </Toolbar>
                <Divider sx={{ borderColor: 'rgba(255, 255, 255, 0.1)' }} />
                <List
                    component="nav"
                    sx={{
                        px: 2,
                        py: 1,
                        '& .MuiListItemButton-root': {
                            borderRadius: '12px',
                            mb: 0.5,
                            '&:hover': {
                                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                            },
                            '&.Mui-selected': {
                                backgroundColor: `${LIGHT_PURPLE}40`,
                                '&:hover': {
                                    backgroundColor: `${LIGHT_PURPLE}60`,
                                },
                            },
                        },
                        '& .MuiListItemIcon-root': {
                            color: 'rgba(255, 255, 255, 0.7)',
                            minWidth: 40,
                        },
                        '& .MuiListItemText-primary': {
                            fontSize: '0.95rem',
                            fontWeight: 500,
                        },
                    }}
                >
                    <MainListItems />
                    <Divider sx={{ my: 2, borderColor: 'rgba(255, 255, 255, 0.1)' }} />
                    <SecondaryListItems />
                </List>
            </Drawer>
            <MainContent
                component="main"
                sx={{
                    marginLeft: open ? `${drawerWidth}px` : {
                        xs: theme => theme.spacing(7),
                        sm: theme => theme.spacing(9)
                    },
                    transition: theme => theme.transitions.create('margin', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.enteringScreen,
                    }),
                }}
            >
                <Toolbar />
                <Container
                    maxWidth={false}
                    sx={{
                        mt: 4,
                        mb: 4,
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        px: { xs: 2, sm: 3 },
                        mx: 0,
                        width: '100%',
                    }}
                >
                    <Box sx={{ flexGrow: 1 }}>
                        <Outlet context={{ user }} />
                    </Box>
                    <Box sx={{ mt: 'auto', pt: 4 }}>
                        <MyCopyright />
                    </Box>
                </Container>
            </MainContent>
            {/* <TwitchBlockNotification /> */}
        </Box>
    );
}
