import * as React from 'react';
import { useState, useEffect } from 'react';
import {
    Box,
    Breadcrumbs,
    Button,
    Card,
    CardContent,
    Container,
    Grid,
    Link,
    Typography,
    IconButton,
    Tooltip,
    CircularProgress
} from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import Requester from '../../../utils/requester';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useNotification } from '../../../context/NotificationContext';
import { getRewardSchemas, getActionDisplayName } from './reward-schemas';
import { useTranslation } from 'react-i18next';

export default function RewardsList() {
    const [rewards, setRewards] = useState([]);
    const [loading, setLoading] = useState(true);
    const [deletingId, setDeletingId] = useState(null);
    const navigate = useNavigate();
    const { showNotification } = useNotification();
    const { t } = useTranslation();
    
    // Get schemas using the translation function
    const ACTION_SCHEMAS = getRewardSchemas(t);

    const fetchRewards = async () => {
        setLoading(true);
        try {
            const response = await Requester.get('channel/rewards/');
            if (response.status === 200) {
                const data = await response.json();
                setRewards(data);
            }
        } catch (error) {
            console.error('Error fetching rewards:', error);
            showNotification(t("rewards_list.error_loading_rewards"), {
                type: 'error',
                autoHideDuration: 3000
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchRewards();
    }, []);

    const handleDelete = async (id) => {
        if (deletingId) return;
        
        setDeletingId(id);
        try {
            const response = await Requester.delete(`channel/rewards/${id}/`);
            if (response.status === 200 || response.status === 204) {
                setRewards(prevRewards => prevRewards.filter(reward => reward.id !== id));
                showNotification(t("rewards_list.reward_deleted_successfully"), {
                    type: 'success',
                    autoHideDuration: 3000
                });
            }
        } catch (error) {
            showNotification(t("rewards_list.reward_delete_error"), {
                type: 'error',
                autoHideDuration: 3000
            });
        } finally {
            setDeletingId(null);
        }
    };

    const handleCreateNew = () => {
        navigate('/dashboard/rewards/new');
    };

    const columns = [
        {
            field: 'title',
            headerName: t("rewards_list.title_column"),
            width: 250,
            flex: 1,
            sortable: false,
        },
        {
            field: 'action_class',
            headerName: t("rewards_list.action_type_column"),
            width: 200,
            flex: 1,
            sortable: false,
            renderCell: (params) => (
                <Typography>
                    {getActionDisplayName(ACTION_SCHEMAS, params.value)}
                </Typography>
            ),
        },
        {
            field: 'is_active',
            headerName: t("rewards_list.active_column"),
            width: 150,
            type: 'boolean',
            sortable: false,
        },
        {
            field: 'actions',
            headerName: t("rewards_list.actions_column"),
            width: 180,
            sortable: false,
            renderCell: (params) => {
                const isDeleting = deletingId === params.row.id;
                return (
                    <Box sx={{ display: 'flex', gap: 1 }}>
                        <IconButton
                            onClick={() => navigate(`/dashboard/rewards/${params.row.id}/edit`)}
                            disabled={isDeleting}
                            sx={{
                                color: 'primary.main',
                                '&:hover': {
                                    bgcolor: 'primary.dark',
                                    color: 'white'
                                }
                            }}
                        >
                            <EditIcon />
                        </IconButton>
                        <IconButton
                            onClick={() => handleDelete(params.row.id)}
                            disabled={isDeleting}
                            sx={{
                                color: 'error.main',
                                '&:hover': {
                                    bgcolor: 'error.dark',
                                    color: 'white'
                                }
                            }}
                        >
                            {isDeleting ? <CircularProgress size={20} /> : <DeleteIcon />}
                        </IconButton>
                    </Box>
                );
            },
        },
    ];

    return (
        <Box sx={{ minHeight: '100vh', pb: 8 }}>
            <Container maxWidth="lg" sx={{ pt: 4 }}>
                {/* Header Section */}
                <Box sx={{ mb: 4 }}>
                    <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
                        <Link
                            underline="hover"
                            color="inherit"
                            to="/dashboard"
                            component={RouterLink}
                            sx={{
                                '&:hover': { color: 'primary.main' }
                            }}
                        >
                            {t("rewards_list.home")}
                        </Link>
                        <Typography color="primary">{t("rewards_list.rewards_title")}</Typography>
                    </Breadcrumbs>

                    <Box sx={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        justifyContent: 'space-between',
                        mb: 3 
                    }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            <EmojiEventsIcon sx={{ fontSize: 32, color: 'primary.main' }} />
                            <Typography variant="h4" fontWeight="500">
                                {t("rewards_list.rewards_title")}
                            </Typography>
                        </Box>
                        <Button
                            variant="contained"
                            startIcon={<AddIcon />}
                            onClick={handleCreateNew}
                            sx={{
                                backgroundColor: 'primary.main',
                                '&:hover': {
                                    backgroundColor: 'primary.dark',
                                },
                            }}
                        >
                            {t("rewards_list.create_new_reward")}
                        </Button>
                    </Box>
                </Box>

                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Card
                            elevation={0}
                            sx={{
                                borderRadius: 2,
                                border: '1px solid',
                                borderColor: 'divider',
                            }}
                        >
                            <CardContent sx={{ p: 0 }}>
                                <Box sx={{ height: 600, width: '100%' }}>
                                    <DataGrid
                                        rows={rewards}
                                        columns={columns}
                                        hideFooter={true}
                                        disableColumnMenu={true}
                                        disableRowSelectionOnClick
                                        slots={{
                                            noRowsOverlay: () => (
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                                    <Typography>{t("rewards_list.no_rewards")}</Typography>
                                                </Box>
                                            )
                                        }}
                                        sx={{
                                            border: 'none',
                                            '& .MuiDataGrid-cell': {
                                                borderColor: 'divider',
                                            },
                                            '& .MuiDataGrid-columnHeaders': {
                                                backgroundColor: 'rgba(255, 255, 255, 0.05)',
                                                borderColor: 'divider',
                                            },
                                            '& .MuiDataGrid-footerContainer': {
                                                borderColor: 'divider',
                                            },
                                        }}
                                    />
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
} 