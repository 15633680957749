import * as React from 'react';
import {useEffect, useState, useCallback} from 'react';
import Grid from "@mui/material/Grid";
import {
    Alert,
    Breadcrumbs,
    Checkbox,
    FormControlLabel,
    InputLabel,
    NativeSelect,
    Tooltip,
    Card,
    CardContent,
    Stack,
    IconButton,
    Switch,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import {Link as RouterLink} from "react-router-dom";
import Container from "@mui/material/Container";
import Requester from "../../../utils/requester";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import {ym_hit} from "../../../utils/yandex";
import { useNotification } from '../../../context/NotificationContext';
import { useUser } from '../../../context/UserContext';
import SettingsIcon from '@mui/icons-material/Settings';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import TranslateIcon from '@mui/icons-material/Translate';
import BuildIcon from '@mui/icons-material/Build';
import InfoIcon from '@mui/icons-material/Info';
import CustomSelect from '../../../components/CustomSelect';
import LockIcon from '@mui/icons-material/Lock';
import { useTranslation } from 'react-i18next';
const getChannelData = async () => {
    const response = await Requester.get(`channel/edit/`)
    const channel = await response.json()
    console.log(channel)
    return channel
}

export default function ChannelForm() {
    const { showNotification } = useNotification();
    const { user, hasActiveSubscription, hasFeatureAccess } = useUser();
    const [channelData, setChannelData] = useState(null);
    const { t } = useTranslation();
    const [state, setState] = useState({
        loading: false
    });
    const [languageChangeDialog, setLanguageChangeDialog] = useState({
        open: false,
        newLanguage: null,
        languageName: '',
        isDefaultLanguageSwitch: false
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getChannelData();
                setChannelData(data);
            } catch (error) {
                console.error('Error fetching channel data:', error);
                showNotification(t("channel_form.error_fetching_channel_data"), {
                    type: 'error',
                    autoHideDuration: 3000
                });
            }
        };
        fetchData();
    }, [showNotification]);

    const saveChanges = useCallback(async (fieldName, value) => {
        if (state.loading) return;
        
        setState({ ...state, loading: true });
        
        const dataToSend = {
            ...channelData,
            [fieldName]: value
        };

        try {
            const response = await Requester.post(`channel/edit/`, dataToSend);
            if (response.status === 200) {
                const data = await getChannelData();
                setChannelData(data);
                showNotification(t("channel_form.settings_saved"), {
                    type: 'success',
                    autoHideDuration: 3000
                });
            } else if (response.status === 400) {
                const data = await response.json();
                console.log(data);
                showNotification(t("channel_form.error_saving_settings"), {
                    type: 'error',
                    autoHideDuration: 3000
                });
            }
        } catch (e) {
            console.log(e);
            showNotification(t("channel_form.error_saving_settings"), {
                type: 'error',
                autoHideDuration: 3000
            });
        } finally {
            setState({ ...state, loading: false });
        }
    }, [state, channelData, showNotification]);

    const handleChange = (event) => {
        const { name, type, checked, value } = event.target;
        const newValue = type === 'checkbox' ? checked : value;
        
        // Check if user has subscription for keyboard translation
        if (name === 'keyboard_translation_enabled' && newValue && !hasFeatureAccess('punto_switcher')) {
            showNotification(t("channel_form.keyboard_translation_enabled_error"), {
                type: 'warning',
                autoHideDuration: 3000
            });
            return;
        }
        
        // Handle numeric fields with empty values
        if (name === 'translation_min_words' && (value === '' || value === null || value === undefined)) {
            saveChanges(name, 2);
            return;
        }
        
        if (name === 'translation_min_symbols' && (value === '' || value === null || value === undefined)) {
            saveChanges(name, 3);
            return;
        }

        // Show confirmation dialog when changing target language
        if (name === 'translation_lang') {
            const selectedOption = channelData.translation_lang_options.find(option => option.code === value);
            if (selectedOption && value !== channelData.translation_lang && channelData.not_default_allowed_languages) {
                setLanguageChangeDialog({
                    open: true,
                    newLanguage: value,
                    languageName: selectedOption.name,
                    isDefaultLanguageSwitch: false
                });
                return;
            }
        }
        
        // Show confirmation dialog when switching from custom to default allowed languages
        if (name === 'not_default_allowed_languages' && channelData.not_default_allowed_languages && !newValue) {
            const selectedOption = channelData.translation_lang_options.find(option => option.code === channelData.translation_lang);
            if (selectedOption) {
                setLanguageChangeDialog({
                    open: true,
                    newLanguage: channelData.translation_lang,
                    languageName: selectedOption.name,
                    isDefaultLanguageSwitch: true
                });
                return;
            }
        }
        
        saveChanges(name, newValue);
    };

    const handleLanguageChangeConfirm = () => {
        if (languageChangeDialog.isDefaultLanguageSwitch) {
            // When switching to default languages, update not_default_allowed_languages to false
            saveChanges('not_default_allowed_languages', false);
        } else {
            // When changing the target language, update translation_lang
            saveChanges('translation_lang', languageChangeDialog.newLanguage);
        }
        
        setLanguageChangeDialog({
            open: false,
            newLanguage: null,
            languageName: '',
            isDefaultLanguageSwitch: false
        });
    };

    const handleLanguageChangeCancel = () => {
        setLanguageChangeDialog({
            open: false,
            newLanguage: null,
            languageName: '',
            isDefaultLanguageSwitch: false
        });
    };

    ym_hit('/dashboard/channel-edit');

    if (!channelData) {
        return (
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '60vh' }}>
                    <Typography variant="h6" color="text.secondary">{t("channel_form.loading")}</Typography>
                </Box>
            </Container>
        );
    }

    return (
        <Box sx={{ minHeight: '100vh', pb: 8 }}>
            <Container maxWidth="lg" sx={{ pt: 4 }}>
                {/* Header Section */}
                <Box sx={{ mb: 4 }}>
                    <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
                        <Link 
                            underline="hover" 
                            color="inherit" 
                            to={"/dashboard"} 
                            component={RouterLink}
                            sx={{ 
                                '&:hover': { color: 'primary.main' }
                            }}
                        >
                            {t("channel_form.home")}
                        </Link>
                        <Typography color="primary">{t("channel_form.channel_settings")}</Typography>
                    </Breadcrumbs>
                    
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 3 }}>
                        <SettingsIcon sx={{ fontSize: 32, color: 'primary.main' }} />
                        <Typography variant="h4" fontWeight="500">
                            {t("channel_form.channel_settings")}
                        </Typography>
                    </Box>
                </Box>

                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Card 
                            elevation={0}
                            sx={{ 
                                borderRadius: 2,
                                border: '1px solid',
                                borderColor: 'divider',
                                overflow: 'visible'
                            }}
                        >
                            <CardContent sx={{ p: 4 }}>
                                {/* Basic Settings Section */}
                                <Box sx={{ mb: 5 }}>
                                    <Typography variant="h6" sx={{ mb: 3, display: 'flex', alignItems: 'center', gap: 1 }}>
                                        <SettingsIcon sx={{ color: 'primary.main' }} />
                                        {t("channel_form.basic_settings_title")}
                                    </Typography>
                                    
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                value={channelData.name}
                                                id="name"
                                                disabled={true}
                                                autoComplete="off"
                                                label={t("channel_form.channel_name")}
                                                fullWidth
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                value={channelData.bot_name}
                                                disabled={true}
                                                id="bot_name"
                                                label={t("channel_form.bot_name")}
                                                fullWidth
                                                variant="outlined"
                                            />
                                        </Grid>
                                        
                                        {channelData.is_bot_banned && (
                                            <Grid item xs={12}>
                                                <Alert 
                                                    severity="error"
                                                    sx={{
                                                        borderRadius: 2,
                                                    }}
                                                >
                                                    {t("channel_form.bot_banned_error")}
                                                </Alert>
                                            </Grid>
                                        )}
                                        
                                        <Grid item xs={12}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            color="primary"
                                                            name="is_active" 
                                                            checked={channelData.is_active}
                                                            onChange={handleChange}
                                                            disabled={state.loading}
                                                        />
                                                    }
                                                    label={channelData.is_active ? t("channel_form.bot_active") : t("channel_form.bot_disabled")}
                                                />
                                                <Typography 
                                                    variant="body2" 
                                                    color={channelData.is_active ? "success.main" : "text.secondary"}
                                                >
                                                    {!channelData.is_active && t("channel_form.bot_not_active")}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        
                                        <Grid item xs={12} md={6}>
                                            <Tooltip title={t("channel_form.channel_base_lang_tooltip")}>
                                                <Box>
                                                    {channelData.channel_base_lang_options && (
                                                        <CustomSelect
                                                            value={channelData.channel_base_lang_options.find(option => option.code === channelData.channel_base_lang)?.name || ''}
                                                            options={channelData.channel_base_lang_options.map(option => option.name)}
                                                            onChange={(value) => {
                                                                // Find the code for the selected language name
                                                                const selectedOption = channelData.channel_base_lang_options.find(option => option.name === value);
                                                                if (selectedOption) {
                                                                    handleChange({ target: { name: 'channel_base_lang', value: selectedOption.code } });
                                                                }
                                                            }}
                                                            disabled={state.loading}
                                                            label={t("channel_form.channel_base_lang_label")}
                                                            placeholder={t("channel_form.channel_base_lang_placeholder")}
                                                            searchPlaceholder={t("channel_form.channel_base_lang_search_placeholder")}
                                                        />
                                                    )}
                                                </Box>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </Box>

                                <Divider sx={{ my: 4 }} />

                                {/* Currency Converter Section */}
                                <Box sx={{ mb: 5 }}>
                                    <Typography variant="h6" sx={{ mb: 3, display: 'flex', alignItems: 'center', gap: 1 }}>
                                        <CurrencyExchangeIcon sx={{ color: 'primary.main' }} />
                                        {t("channel_form.currency_converter_title")}
                                    </Typography>
                                    
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} md={6}>
                                            <Tooltip title={t("channel_form.currency_converter_tooltip")}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox 
                                                            color="primary"
                                                            name="enable_rate_finder"
                                                            checked={channelData.enable_rate_finder}
                                                            onChange={handleChange}
                                                        />
                                                    }
                                                    label={t("channel_form.enable_rate_finder_label")}
                                                />
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <CustomSelect
                                                value={channelData.channel_currency}
                                                options={channelData.currencies}
                                                onChange={(value) => handleChange({ target: { name: 'channel_currency', value } })}
                                                disabled={state.loading}
                                                label={t("channel_form.channel_currency_label")}
                                                placeholder={t("channel_form.channel_currency_placeholder")}
                                                searchPlaceholder={t("channel_form.channel_currency_search_placeholder")}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>

                                <Divider sx={{ my: 4 }} />

                                {/* Translator Section */}
                                <Box sx={{ mb: 5 }}>
                                    <Typography variant="h6" sx={{ mb: 3, display: 'flex', alignItems: 'center', gap: 1 }}>
                                        <TranslateIcon sx={{ color: 'primary.main' }} />
                                        {t("channel_form.translator_title")}
                                    </Typography>
                                    
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} md={6}>
                                            <Tooltip title={t("channel_form.translator_tooltip")}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox 
                                                            color="primary"
                                                            name="translation_enabled"
                                                            checked={channelData.translation_enabled}
                                                            onChange={handleChange}
                                                        />
                                                    }
                                                    label={t("channel_form.translate_messages_label")}
                                                />
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            {channelData.channel_base_lang !== 'en' && (
                                                <Tooltip title={
                                                    !hasFeatureAccess('punto_switcher')
                                                    ? t("channel_form.keyboard_translation_enabled_error")
                                                    : t("channel_form.keyboard_translation_enabled_tooltip")
                                                }>
                                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox 
                                                                    color="primary"
                                                                    name="keyboard_translation_enabled"
                                                                    checked={channelData.keyboard_translation_enabled}
                                                                    onChange={handleChange}
                                                                    disabled={!hasFeatureAccess('punto_switcher')}
                                                                />
                                                            }
                                                            label={t("channel_form.keyboard_translation_enabled_label")}
                                                        />
                                                        {!hasFeatureAccess('punto_switcher') && (
                                                            <LockIcon 
                                                                sx={{ 
                                                                    ml: 1, 
                                                                    color: 'action.disabled',
                                                                    fontSize: 20 
                                                                }} 
                                                            />
                                                        )}
                                                    </Box>
                                                </Tooltip>
                                            )}
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            {channelData.translation_enabled ? (
                                                <Tooltip title={t("channel_form.translation_lang_tooltip")}>
                                                    <Box>
                                                        {channelData.translation_lang_options && (
                                                            <CustomSelect
                                                                value={channelData.translation_lang_options.find(option => option.code === channelData.translation_lang)?.name || ''}
                                                                options={channelData.translation_lang_options.map(option => option.name)}
                                                                onChange={(value) => {
                                                                    // Find the code for the selected language name
                                                                    const selectedOption = channelData.translation_lang_options.find(option => option.name === value);
                                                                    if (selectedOption) {
                                                                        handleChange({ target: { name: 'translation_lang', value: selectedOption.code } });
                                                                    }
                                                                }}
                                                                disabled={state.loading}
                                                                label={t("channel_form.translation_lang_label")}
                                                                placeholder={t("channel_form.translation_lang_placeholder")}
                                                                searchPlaceholder={t("channel_form.translation_lang_search_placeholder")}
                                                            />
                                                        )}
                                                    </Box>
                                                </Tooltip>
                                            ) : null}
                                        </Grid>
                                        
                                        {channelData.translation_enabled &&  (
                                            <Grid item xs={12}>
                                                <Tooltip title={t("channel_form.use_default_allowed_languages_tooltip")}>
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                color="primary"
                                                                name="use_default_allowed_languages"
                                                                checked={!channelData.not_default_allowed_languages}
                                                                onChange={(e) => handleChange({ 
                                                                    target: { 
                                                                        name: 'not_default_allowed_languages', 
                                                                        type: 'checkbox',
                                                                        checked: !e.target.checked 
                                                                    } 
                                                                })}
                                                                disabled={state.loading}
                                                            />
                                                        }
                                                        label={t("channel_form.use_default_allowed_languages_label")}
                                                    />
                                                </Tooltip>
                                            </Grid>
                                        )}
                                        
                                        {channelData.translation_enabled && channelData.not_default_allowed_languages && channelData.allowed_languages_options ? (
                                            <Grid item xs={12}>
                                                <Typography variant="subtitle2" sx={{ mt: 3, mb: 1 }}>
                                                    {t("channel_form.allowed_languages_label")}
                                                    <Tooltip title={t("channel_form.allowed_languages_tooltip")}>
                                                        <InfoIcon sx={{ ml: 1, fontSize: 18, color: 'action.active', verticalAlign: 'middle' }} />
                                                    </Tooltip>
                                                </Typography>
                                                
                                                <Box 
                                                    sx={{ 
                                                        p: 1.5, 
                                                        border: '1px solid',
                                                        borderColor: 'divider',
                                                        borderRadius: 1,
                                                        bgcolor: 'rgba(0, 0, 0, 0.05)',
                                                    }}
                                                >
                                                    <Box 
                                                        sx={{ 
                                                            display: 'grid',
                                                            gridTemplateColumns: {
                                                                xs: 'repeat(1, 1fr)',  // 1 column on extra small screens
                                                                sm: 'repeat(2, 1fr)',  // 2 columns on small screens
                                                                md: 'repeat(3, 1fr)',  // 3 columns on medium screens
                                                                lg: 'repeat(4, 1fr)'   // 4 columns on large screens
                                                            },
                                                            gap: 0.5, // Smaller gap between items
                                                        }}
                                                    >
                                                        {(() => {
                                                            // Get filtered and sorted languages
                                                            const filteredLanguages = channelData.allowed_languages_options
                                                                .filter(option => option.code !== channelData.translation_lang)
                                                                .sort((a, b) => a.name.localeCompare(b.name));
                                                            
                                                            // Default number of columns (will be adjusted by CSS)
                                                            const numColumns = 4;
                                                            
                                                            // Calculate items per column
                                                            const itemsPerColumn = Math.ceil(filteredLanguages.length / numColumns);
                                                            
                                                            // Organize languages by column
                                                            const columns = [];
                                                            for (let i = 0; i < numColumns; i++) {
                                                                columns.push([]);
                                                            }
                                                            
                                                            // Distribute languages into columns
                                                            for (let i = 0; i < filteredLanguages.length; i++) {
                                                                const columnIndex = Math.floor(i / itemsPerColumn);
                                                                if (columnIndex < numColumns) {
                                                                    columns[columnIndex].push(filteredLanguages[i]);
                                                                }
                                                            }
                                                            
                                                            // Flatten the columns array
                                                            const organizedLanguages = columns.flat();
                                                            
                                                            // Create mapping for grid area
                                                            return organizedLanguages.map((option, index) => {
                                                                // Calculate row and column for grid area
                                                                const columnIndex = Math.floor(index / itemsPerColumn);
                                                                const rowIndex = index % itemsPerColumn;
                                                                
                                                                // Check if this language should be excluded
                                                                const currentLangOption = channelData.allowed_languages_options.find(
                                                                    lang => lang.code === channelData.translation_lang
                                                                );
                                                                
                                                                const isExcluded = currentLangOption && 
                                                                    currentLangOption.excluded && 
                                                                    currentLangOption.excluded.includes(option.code);
                                                                
                                                                const tooltipTitle = isExcluded 
                                                                    ? t("channel_form.language_similarity_warning") 
                                                                    : '';
                                                                
                                                                const isChecked = channelData.allowed_languages && 
                                                                    channelData.allowed_languages.includes(option.code);
                                                                
                                                                return (
                                                                    <Tooltip key={option.code} title={tooltipTitle}>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    checked={isChecked}
                                                                                    disabled={isExcluded || state.loading}
                                                                                    onChange={(e) => {
                                                                                        const updatedAllowedLanguages = e.target.checked
                                                                                            ? [...(channelData.allowed_languages || []), option.code]
                                                                                            : (channelData.allowed_languages || []).filter(code => code !== option.code);
                                                                                        
                                                                                        handleChange({
                                                                                            target: {
                                                                                                name: 'allowed_languages',
                                                                                                value: updatedAllowedLanguages
                                                                                            }
                                                                                        });
                                                                                    }}
                                                                                    size="small"
                                                                                />
                                                                            }
                                                                            label={option.name}
                                                                            sx={{
                                                                                m: 0, // Remove default margin
                                                                                opacity: isExcluded ? 0.5 : 1,
                                                                                '& .MuiFormControlLabel-label': {
                                                                                    fontSize: '0.85rem' // Slightly smaller font
                                                                                },
                                                                                gridColumn: columnIndex + 1,
                                                                                gridRow: rowIndex + 1
                                                                            }}
                                                                        />
                                                                    </Tooltip>
                                                                );
                                                            });
                                                        })()}
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        ) : null}
                                        
                                        {channelData.translation_enabled && (
                                            <>
                                                <Grid item xs={12}>
                                                    <Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>
                                                        {t("channel_form.translation_min_requirements")}
                                                    </Typography>
                                                </Grid>
                                                {(channelData.translation_min_words < 2 || channelData.translation_min_symbols < 3) && (
                                                    <Grid item xs={12}>
                                                        <Alert 
                                                            severity="warning" 
                                                            sx={{ 
                                                                mb: 2,
                                                                borderRadius: 2,
                                                            }}
                                                        >
                                                            {t("channel_form.translation_min_requirements_warning")}
                                                        </Alert>
                                                    </Grid>
                                                )}
                                                <Grid item xs={12} md={6}>
                                                    <Tooltip title={t("channel_form.translation_min_words_tooltip")}>
                                                        <Box>
                                                            <CustomSelect
                                                                value={String(channelData.translation_min_words === null || channelData.translation_min_words === undefined ? 2 : channelData.translation_min_words)}
                                                                options={Array.from({length: 15}, (_, i) => String(i + 1))}
                                                                onChange={(value) => handleChange({ target: { name: 'translation_min_words', value: parseInt(value, 10) } })}
                                                                disabled={state.loading}
                                                                label={t("channel_form.translation_min_words_label")}
                                                                placeholder={t("channel_form.translation_min_words_placeholder")}
                                                                searchPlaceholder={t("common.search_placeholder")}
                                                            />
                                                        </Box>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <Tooltip title={t("channel_form.translation_min_symbols_tooltip")}>
                                                        <Box>
                                                            <CustomSelect
                                                                value={String(channelData.translation_min_symbols === null || channelData.translation_min_symbols === undefined ? 3 : channelData.translation_min_symbols)}
                                                                options={Array.from({length: 30}, (_, i) => String(i + 1))}
                                                                onChange={(value) => handleChange({ target: { name: 'translation_min_symbols', value: parseInt(value, 10) } })}
                                                                disabled={state.loading}
                                                                label={t("channel_form.translation_min_symbols_label")}
                                                                placeholder={t("channel_form.translation_min_symbols_placeholder")}
                                                                searchPlaceholder={t("common.search_placeholder")}
                                                            />
                                                        </Box>
                                                    </Tooltip>
                                                </Grid>
                                            </>
                                        )}
                                    </Grid>
                                </Box>

                                <Divider sx={{ my: 4 }} />

                                {/* Utilities Section */}
                                <Box>
                                    <Typography variant="h6" sx={{ mb: 3, display: 'flex', alignItems: 'center', gap: 1 }}>
                                        <BuildIcon sx={{ color: 'primary.main' }} />
                                        {t("channel_form.utilities_title")}
                                    </Typography>
                                    
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} md={6}>
                                            <Tooltip title={t("channel_form.top_enabled_tooltip")}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox 
                                                            color="primary"
                                                            name="top_enabled"
                                                            checked={channelData.top_enabled}
                                                            onChange={handleChange}
                                                        />
                                                    }
                                                    label={t("channel_form.top_enabled_label")}
                                                />
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Tooltip title={t("channel_form.shazam_enabled_tooltip")}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox 
                                                            color="primary"
                                                            name="is_shazam_enabled"
                                                            checked={channelData.is_shazam_enabled}
                                                            onChange={handleChange}
                                                        />
                                                    }
                                                    label={t("channel_form.shazam_enabled_label")}
                                                />
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Tooltip title={t("channel_form.weather_enabled_tooltip")}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox 
                                                            color="primary"
                                                            name="weather_enabled"
                                                            checked={channelData.weather_enabled}
                                                            onChange={handleChange}
                                                        />
                                                    }
                                                    label={t("channel_form.weather_enabled_label")}
                                                />
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>

                {/* Language Change Confirmation Dialog */}
                <Dialog
                    open={languageChangeDialog.open}
                    onClose={handleLanguageChangeCancel}
                    aria-labelledby="language-change-dialog-title"
                    aria-describedby="language-change-dialog-description"
                >
                    <DialogTitle id="language-change-dialog-title">
                        {languageChangeDialog.isDefaultLanguageSwitch 
                            ? t("channel_form.default_languages_change_title") 
                            : t("channel_form.language_change_title")}
                    </DialogTitle>
                    <DialogContent>
                        <Typography id="language-change-dialog-description" sx={{ mb: 2 }}>
                            {languageChangeDialog.isDefaultLanguageSwitch
                                ? t("channel_form.default_languages_change_description")
                                : t("channel_form.language_change_description", { language: languageChangeDialog.languageName })}
                        </Typography>
                        <Alert severity="warning" sx={{ borderRadius: 2, mb: 2 }}>
                            {languageChangeDialog.isDefaultLanguageSwitch
                                ? t("channel_form.default_languages_change_warning")
                                : t("channel_form.language_change_warning")}
                        </Alert>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleLanguageChangeCancel} color="primary">
                            {t("common.cancel")}
                        </Button>
                        <Button 
                            onClick={handleLanguageChangeConfirm} 
                            color="primary"
                            disabled={state.loading}
                        >
                            {state.loading ? t("common.processing") : t("common.confirm")}
                        </Button>
                    </DialogActions>
                </Dialog>
            </Container>
        </Box>
    );
}